import { useRef, useState, useEffect, React } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, Card, Col, Form, Row, Image } from "react-bootstrap";
// import bg1 from "../../assets/img/login/bg.jpg";
import bg1 from "../../assets/img/Zoom_Background.png";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import logo from "../../assets/img/logo/main-logo.png";
import LoadingSpinner from "../_common/LoadingSpinner";
// const LOGIN_URL = "http://40.76.109.65:704/api/Auth/login";
const BASE_URL = "https://argateway.cxengine.net/api/";
// const BASE_URL = "https://localhost:44387/api/";
//  const BASE_URL = "https://arworkflow.innovalglobal.com:802/api/";
export default function LoginComponent() {
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const userRef = useRef();
  const errRef = useRef();

  const [user, setUser] = useState("");
  const [pwd, setPwd] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });

  useEffect(() => {
    userRef.current.focus();
  }, []);

  useEffect(() => {
    setErrMsg("");
  }, [user, pwd]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading({
        isLoading: true,
        value: "login",
      });

      const response = await axios.post(
        `${BASE_URL}Auth/login`,
        JSON.stringify({ username: user, password: pwd }),
        { headers: { "Content-Type": "application/json" } }
      );

      const { accessToken, refreshToken, roles, id, isResetDefaultPass, name } =
        response?.data;

      const commonFieldResponse = await axios.get(
        `${BASE_URL}Setting/get-common-field-type`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );

      sessionStorage.setItem(
        "commonField",
        JSON.stringify(commonFieldResponse?.data?.result?.commonFieldTypes)
      );
      sessionStorage.setItem(
        "amdStatuscommonField",
        JSON.stringify(commonFieldResponse?.data?.result?.amdStatusCommontypes)
      );
      sessionStorage.setItem("auth", JSON.stringify(response?.data));

      setAuth({
        user,
        pwd,
        roles,
        accessToken,
        refreshToken,
        id,
        isResetDefaultPass,
        name,
      });

      if (isResetDefaultPass) {
        setLoading({ isLoading: false, value: "login" });
        navigate("/account/setup-password");
        setUser("");
        setPwd("");
        return;
      }
      await handleRoleBasedNavigation(roles[0], accessToken);

      setUser("");
      setPwd("");
    } catch (err) {
      handleLoginError(err);
    } finally {
      setLoading({
        isLoading: false,
        value: "login",
      });
    }
  };

  const handleRoleBasedNavigation = async (role, accessToken) => {    
    switch (role) {
      case "Employee":
        await fetchAndStoreWorkflowComponents(accessToken);
        navigate("/MyTask/manage");
        break;

      case "Master":
        navigate("/dashboard");
        break;

      case "Team Lead":
        navigate("/dashboard");
        break;

      default:
        navigate("/dashboard");
        break;
    }
  };

  const fetchAndStoreWorkflowComponents = async (accessToken) => {
    try {
      setLoading({
        isLoading: true,
        value: "getWorkflowComponentList",
      });
      const workflowListData = await axios.get(
        `${BASE_URL}Setting/get-workflow-component-List`,
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      sessionStorage.setItem(
        "WorkflowComponentList",
        JSON.stringify(workflowListData?.data)
      );
    } catch (err) {
      console.error("Error fetching workflow components:", err);
    } finally {
      setLoading({
        isLoading: false,
        value: "getWorkflowComponentList",
      });
    }
  };

  const handleLoginError = (err) => {
    if (!err?.response) {
      setErrMsg("No Server Response");
    } else if (err.response?.status === 400) {
      setErrMsg("Missing Username or Password");
    } else if (err.response?.status === 401) {
      setErrMsg("Unauthorized");
    } else {
      setErrMsg("Login Failed");
    }
    errRef.current.focus();
  };

  return (
    <div className="page-sign d-block py-0">
      <Row className="g-0">
        <Col md="7" lg="4" className="col-wrapper">
          <Card className="card-sign">
            <Card.Header>
              <Card.Title>Login</Card.Title>
              <Card.Text className="text-dark">
                Welcome back! Please login to continue.
              </Card.Text>
            </Card.Header>
            <Card.Body>
              <Form>
                <div className="mb-4">
                  <Form.Label>Username</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter your Username"
                    name="username"
                    id="username"
                    ref={userRef}
                    autoComplete="off"
                    onChange={(e) => setUser(e.target.value)}
                    value={user}
                    required
                  />
                </div>
                <div className="mb-4">
                  <Form.Label className="d-flex justify-content-between">
                    Password
                  </Form.Label>
                  <Form.Control
                    autoComplete="off"
                    name="password"
                    placeholder="Enter your password"
                    type="password"
                    id="password"
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    required
                  />
                </div>
                <p
                  ref={errRef}
                  className={errMsg ? "errmsg" : "offscreen"}
                  aria-live="assertive"
                >
                  {errMsg}
                </p>
                <Button
                  type="button"
                  className="btn-sign"
                  disabled={
                    loading.isLoading &&
                    (loading?.value === "login" ||
                      loading?.value === "getWorkflowComponentList")
                  }
                  onClick={handleSubmit}
                >
                  {loading.isLoading &&
                  (loading?.value === "login" ||
                    loading?.value === "getWorkflowComponentList") ? (
                    <LoadingSpinner
                      color="#ffffff"
                      size={30}
                      type={"TailSpin"}
                    />
                  ) : (
                    "Let me in"
                  )}
                </Button>

                <div className="text-end mt-1">
                  <Link to="/account/forgot-password" className="float-end">
                    Forgot password?
                  </Link>
                </div>
              </Form>
            </Card.Body>
            <Card.Footer>
              {/* <Link to="/account/signup">Create an Account</Link> */}
            </Card.Footer>
          </Card>
        </Col>
        <Col className="d-none d-lg-block position-relative" style={{backgroundImage: `url(${bg1})`, backgroundSize: "cover",backgroundRepeat: "no-repeat"}}>
          {/* <Image src={bg1} className="h-100 w-100 " alt="bg" /> */}
          <div className="login-content ms-12">
            <h2 className="text-white mb-2">Welcome to DRIP</h2>
            <h1 className="text-white">
             Denials Resolution Intelligence Program
            </h1>
          </div>
        </Col>
        {/*opacity-25 <Col className="d-none d-lg-block position-relative"> */}
          {/* <div className="login-content-logo">
            <Image className="wt-200 mb-3" src={logo} />
          </div> */}

          
        {/* </Col> */}
      </Row>
    </div>
  );
}
