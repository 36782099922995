import React, { useState } from "react";
import { Offcanvas, Button, Form } from "react-bootstrap";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import useAuth from "../../../hooks/useAuth";
import ToastifyService from "../../_common/ToastifyService";
import Commonfield from "../../../infrastructure/core/Commonfield";
import LoadingSpinner from "../../_common/LoadingSpinner";

export default function RespondComponent(props) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [remark, setRemark] = useState("");
  const [loading, setLoading] = useState({
    isLoading: false,
    value: "",
  });

  const handleSubmit = async () => {
    try {
      setLoading({
        isLoading: true,
        value: "tlresponse",
      });
      const TlPendingQuery = await axiosPrivate.post(`Task/tl-response`, {
        status: Commonfield.parkStatus.payerClosed,
        createdBy: auth?.id,
        id: props?.id,
        visitId: props?.visitId?.toString(),
        remark: remark,
        caseId: props?.taskId,
      });
      props?.setPendingQueries(TlPendingQuery?.data?.result);
      props?.closeFunction({
        showPendingQueriesClosed: false,
        id: "",
        visitId: "",
        agentQuery: "",
      });
      ToastifyService.success("Tl responded");
    } catch (err) {
      ToastifyService.error("Oops! Something Went Wrong");
      setLoading({
        isLoading: false,
        value: "tlresponse",
      });
    } finally {
      setLoading({
        isLoading: false,
        value: "tlresponse",
      });
    }
  };

  return (
    <React.Fragment>
      <Offcanvas
        show={props.show}
        onHide={() =>
          props?.closeFunction({
            showPendingQueriesClosed: false,
            taskId: "",
            visitId: "",
            agentQuery: "",
          })
        }
        placement="end"
        className="w-30"
      >
        <Offcanvas.Header
          closeButton
          // onClick={() =>
          //   props?.closeFunction({
          //     showPendingQueriesClosed: false,
          //     taskId: "",
          //     visitId: "",
          //     agentQuery: "",
          //   })
          // }
        >
          <Offcanvas.Title className="fs-16 text-dark">Respond</Offcanvas.Title>
        </Offcanvas.Header>

        <Offcanvas.Body>
          <Form.Label>Agent Query</Form.Label>
          <p className="mb-4 fs-14">
            {props?.agentQuery}
            {/* Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged.{" "} */}
          </p>
          <div>
            <Form.Label>Your Response</Form.Label>
            <Form.Control
              as="textarea"
              rows={4}
              placeholder="Enter Message..."
              onChange={(e) => setRemark(e.target.value)}
            />
          </div>
        </Offcanvas.Body>

        <div className="offcanvas-footer justify-content-start">
          <Button
            type="button"
            variant="primary"
            className="fs-14 me-2 d-flex align-items-center"
            disabled={loading.isLoading && loading?.value === "tlresponse"}
            onClick={handleSubmit}
          >
            {loading.isLoading && loading?.value === "tlresponse" ? (
              <LoadingSpinner color="#ffffff" size={30} type={"TailSpin"} />
            ) : (
              <>
                <i className="ri-add-line fs-18 lh-1 align-middle"></i>
                <span className="align-middle">Respond</span>
              </>
            )}
          </Button>
        </div>
      </Offcanvas>
    </React.Fragment>
  );
}
